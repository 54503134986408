<script lang="ts">
export let network: string
</script>

<span class="network">{network}</span>

<style lang="scss">
span {
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 0.32px;
  position: relative;
  padding-bottom: 7px;
  width: fit-content;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    background: linear-gradient(
      90deg,
      #ff560e -15.86%,
      #ff001f 37.83%,
      #8802c8 121.66%,
      #6202fe 152.75%
    );
  }

  @media only screen and (min-width: 1024px) {
    font-size: 16px;
    line-height: 18px;
  }
}
</style>
